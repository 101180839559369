import { DeleteWorkbook, GetMyLibraryWorkbooks, StoreWorkbookItemSuccess } from '../core/store/workbook/workbook.actions';
import { Component, OnInit, Input, OnDestroy, ChangeDetectionStrategy, ElementRef, ViewChild } from '@angular/core';
import { CurriculumService } from '../core/services/curriculum.service';
import { CourseWorkbooksService } from '../core/services/course-workbooks.service';
import { WorkbooksService } from '../core/services/workbooks.service';
import { switchMap, take, tap, filter, map, takeUntil, reduce, delay, distinctUntilChanged, finalize } from 'rxjs/operators';
import { combineLatest, Observable, forkJoin, of, Subject, BehaviorSubject } from 'rxjs';
import { CourseService } from '../core/services/courses.service';
import { ListenerService } from '../core/services/listener.service';
import { LessonPlansService } from '../core/services/lesson-plans.service';
import { BARTON_USER, _variable_images } from '../environments/environment';
import { NgxSpinnerService } from 'ngx-spinner';

import {
  faChevronUp,
  faChevronDown,
  faArrowCircleUp,
  faArrowCircleDown,
  faPlusCircle
} from '@fortawesome/free-solid-svg-icons';
import { UtilityService } from '../core/services/utility.service';
import { CourseWorkbookMenuComponent } from '../core/components/workbooks/course-workbooks.component';
import { ActivatedRoute, Router } from '@angular/router';
import { ActivityTypeService } from '../core/services/activity-type.service';
import { MatDialog } from '@angular/material/dialog';

import { ManageCourseDialogComponent } from '../core/components/manage-course-dialog/manage-course-dialog.component';
import { SubscriptionService } from '../core/services/subscription.service';
import { TutorialDialogComponent } from '../core/components/support/support.component';
import { ConfirmationDialogComponent } from '../core/components/confirmation-dialog/confirmation-dialog.component';

import { SettingsService } from '../core/services/settings.service';

import { LessonPlansStepsService } from '../core/services/lesson-plans-steps.service';
import { DataService } from '../core/services/data.service';
import { NavbarListenerService } from '../core/services/navbar-listener-service..service';
import * as dummyActions from '../core/store/dummy/dummmy-actions';
import { Store, select } from '@ngrx/store';
import * as reducers from '../core/store';
import * as CoursesActions from '../core/store/courses/courses.actions';
import * as SubscriptionsActions from '../core/store/subscriptions/subscriptions.actions';
import * as planActions from '../core/store/plan/plan.actions';
import * as WorkbookActions from '../core/store/workbook/workbook.actions';
import * as CourseWorkbookActions from '../core/store/course-workbook/course-workbook.actions';
import * as SetCourseActions from '../core/store/set-course/set-course.actions';

import { reducer } from '../core/store/dummy/dummy-reducer';
import { ClearAllLessons, CopyLesson, DeleteLesson, GetAllLessonSteps } from '../core/store/lesson/lesson.actions';
import { GetWorkbook } from '../core/store/workbook/workbook.actions';
import { getLessonStep } from '../core/store/edit-lesson/edit-lesson.reducer';
import { GetLessonStep } from '../core/store/edit-lesson/edit-lesson.actions';
import { ActivityHeaderService } from '../core/services/activity-header.service';
import { AsyncPipe, Location } from '@angular/common';
import { MessageDialogComponent } from '../core/components/message-dialog/message-dialog.component';
import { ClearWorkbookActivity } from '../core/store/workbook-activity/workbook-activity.actions';
import { ClearActivityInitData } from '../core/store/activity/activity.actions';
import { ResendEmailVerification } from '../core/store/account/account.actions';
import { AccountService } from '../core/services/account.service';
import { AddWhizzimoWorkbookToCourse, AddWorkbookToCourseSuccess } from '../core/store/course-workbook/course-workbook.actions';
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { WorkbookCardsComponent } from '../core/components/workbooks/workbook-cards.component';
import { LessonPlanCardsComponent } from '../core/components/lesson-plans/lesson-plan-card';

@Component({
  selector: 'app-curriculum',
  templateUrl: './html/curriculum.html',
  styleUrls: ['../assets/css/main.css',
    '../assets/scss/fontawesome.scss',
    '../assets/scss/brands.scss',
    '../assets/scss/regular.scss',
    '../assets/scss/solid.scss',
    './curriculum.scss'
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class CurriculumComponent implements OnInit, OnDestroy {
  @ViewChild('tabGroup') private tabGroup: ElementRef;
  @ViewChild('workbookCardsElement') private workbookCardsElement: WorkbookCardsComponent;
  @ViewChild('lessonPlanCard') private lessonCardsElement: LessonPlanCardsComponent;
  
  @Input() courseMenuOpen: boolean;
  workbooks: any[] = [];
  whizzimoWorkbooks: any[] = [];
  public courseWorkbooks: any[] = [];
  public lessonPlans: any[] = null;

  public courseWorkbooksSubject: any = new BehaviorSubject<any>(this.courseWorkbooks);
  public lessonPlansSubject: any = new BehaviorSubject<any>(this.lessonPlans);

  public lessonPlans$ = this.lessonPlansSubject.asObservable();
  public courseWorkbooks$ = this.courseWorkbooksSubject.asObservable();

  courses: any[] = [];
  currentCourse: any;
  lessonPlanOpen: any[] = [];
  faChevronUp = faChevronUp;
  faChevronDown = faChevronDown;
  faArrowCircleUp = faArrowCircleUp;
  faArrowCircleDown = faArrowCircleDown;
  faPlusCircle = faPlusCircle;
  activityDialog: any;
  currentWorkbook: any;
  userId: string;
  bartonUser = BARTON_USER;

  public activities: any[] = [];
  public isLoading = true;
  canReOrder = false;
  isManaging = false;
  manageCourseForm: UntypedFormGroup;
  subscriptionId: string;
  selectedTabIndex = 0;
  hideWorkbookTabsPredicate = true;
  hideWorkbookTabsPredicate$ = new Subject();

  helpIconImage: any;
  selectedPlan: string;
  dummy$: any;
  isChanged = false;
  areCourseWorkbooksChanged = false;
  areLessonPlansChanged = false;
  private unsubscribe$: Subject<void> = new Subject();

  public isLoadingSubject: any = new BehaviorSubject<any>(true);
  isLoadingObs$ = this.isLoadingSubject.asObservable();

  constructor(
    private _curr: CurriculumService,
    private courseWorkbookService: CourseWorkbooksService,
    private workbookService: WorkbooksService,
    private courseService: CourseService,
    private lessonPlansService: LessonPlansService,
    private lessonPlanStepService: LessonPlansStepsService,
    private utilityService: UtilityService,
    private activityTypeService: ActivityTypeService,
    private dialog: MatDialog,
    private listener: ListenerService,
    private router: Router,
    private subscriptionService: SubscriptionService,
    private navbarListenerService: NavbarListenerService,
    private route: ActivatedRoute,
    private settingsService: SettingsService,
    private dataService: DataService,
    private spinner: NgxSpinnerService,
    private store: Store,
    private async: AsyncPipe,
    private location: Location,
    private activatedRoute: ActivatedRoute,
    private curriculumService: CurriculumService,
    private accountService: AccountService,
    private formBuilder: UntypedFormBuilder
  ) {
    this.route.params.subscribe(param => {
      if (param.type === 'workbook') {
        this.selectedTabIndex = 0;
      } else if (param.type === 'lesson') {
        this.selectedTabIndex = 1;
      } else {
      }
    });
    this.router.routeReuseStrategy.shouldReuseRoute = () => {
      return false;
    };
  }

  ngOnInit() {
    let profile = JSON.parse(localStorage.profile);


    // Check if email is verified and show dialog if not
    if (profile.email_verified && !profile.user_metadata.emailVerificationAccepted) {
      const dialogRef = this.dialog.open(MessageDialogComponent, {
        panelClass: 'custom-confirm-dialog',
        data: {
          title: 'Email Verified',
          content: 'Your email address has been verified.',
          style: 'text-align: left; width: 87%;'
        }
      });

      this.accountService.setEmailVerificationAccepted(profile.user_id).pipe(take(1))
        .subscribe(() => {
          profile.user_metadata.emailVerificationAccepted = true;
          localStorage.setItem('profile', JSON.stringify(profile));
          console.log('email verification accepted');
        });
    }

    // Clears out all of the old lessons stored in the store
    this.store.dispatch(new ClearAllLessons(null));

    // Listens for changes when reordering workbooks and lessons
    // this.isLoadingObs$.pipe(takeUntil(this.unsubscribe$)).subscribe((evt) => {
    //   if (evt && this.isChanged) {
    //     setTimeout(() => {
    //       console.log("Is reaching this")
    //       this.isLoadingSubject.next(false);
    //       this.isChanged = false;
    //     }, 3000);
    //   }
    // });

    // General setup
    ActivityHeaderService.updateOverDeadzone(true);
    this.navbarListenerService.navbarCallback(false);

    // Sets the help icon image
    this.dataService._variable_images.pipe(takeUntil(this.unsubscribe$)).subscribe(path => {
      this.helpIconImage = path + 'help-header.png';
    });
    // this.helpIconImage = _variable_images + 'help-header.png';

    this.listener.listener().pipe(takeUntil(this.unsubscribe$)).subscribe((callback) => {
      const payload = callback.split(':');
      if (payload.length > 1) {
        localStorage.setItem('currentCourseId', payload[1]);
        // this.setCourse(payload[1]);
      }

      if (payload.length === 3) {
        this.subscriptionId = payload[2];
      }
    });


    // Everything has been loaded
    combineLatest([
      this.store.select(reducers.selectGetCourseWorkbookSuccess),
      this.store.select(reducers.selectGetAllLessonStepsSuccess),
      this.store.select(reducers.selectGetAllLessons)
    ])
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(([courseWorkbooks, lessonPlans, lessons]: any) => {
        if (!courseWorkbooks || !lessonPlans || !lessons) {
          return;
        }

        // This checks to see if a course has lessons. If it doesn't, we want to switch to the "workbook" tab as soon as the data is loaded.
        if (lessons.length === 0) {
          this.tabClick({ index: 0 })
        }

        this.isLoading = false;
        this.isLoadingSubject.next(false);
      });


    this.userId = JSON.parse(localStorage.profile).user_metadata.uid;

    // Grabs the course data, course workbooks, and the user's library workbooks. Also makes a request to get the lesson plans (steps)
    combineLatest([
      this.store.select(reducers.getSetCourseData),
      this.store.select(reducers.selectGetAllCourseWorkbook),
      this.store.select(reducers.selectGetMyLibraryWorkbooks),
      this.store.select(reducers.selectGetMyWhizzimoWorkbooks)
    ])
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(([course, courseWorkbooks, workbooks, whizzimoLibrary]) => {
        if (!course || !courseWorkbooks) {
          this.currentCourse = null;
          if (!courseWorkbooks) {
            console.log('course workbooks', courseWorkbooks);
          }
          return;
        }

        console.log('Current course', course);

        this.currentCourse = course;

        this.store.dispatch(new GetAllLessonSteps(course._id));

        console.log("All course information reloaded");
        // console.log(course);

        if (course.subscriptionId) {
          this.subscriptionId = course.subscriptionId;
        }

        // Hides the workbook tab if the user is not a barton user

        this.hideWorkbookTabsPredicate = !(this.currentCourse.teacherKey === this.bartonUser && this.currentCourse.teacherKey !== this.userId || this.currentCourse.teacherKey === "WhizzimoAcademy:53356" && this.currentCourse.teacherKey !== this.userId);
        this.hideWorkbookTabsPredicate$.next(this.hideWorkbookTabsPredicate);
        this.workbooks = JSON.parse(JSON.stringify(workbooks));
        this.whizzimoWorkbooks = JSON.parse(JSON.stringify(whizzimoLibrary));
        this.courseWorkbooksSubject.next(JSON.parse(JSON.stringify(courseWorkbooks)));
        this.sortCourseWorkbooks();
      });

    // Grabs the lesson plans
    this.store.select(reducers.selectGetAllLessonSteps)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(lessonPlans => {
        if (!lessonPlans) {
          return;
        }

        console.log(lessonPlans);
        console.log("All lesson plan information reloaded");

        this.lessonPlansSubject.next(JSON.parse(JSON.stringify(lessonPlans)));
        this.sortLessonPlans();
        this.lessonPlanOpen = lessonPlans.map(() => ({ open: false }));
      });

    // Pulls in the activity types
    this.store.select(reducers.selectActivityTypes)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(activities => {
        if (!activities) {
          this.activities = [];
          return;
        }
        // @ts-ignore
        this.activities = JSON.parse(JSON.stringify(activities)).map(activity => {
          if (activity.name.toLowerCase() === 'blackboard') {
            activity.url = '//activity/blackboard/';
          } else if (activity.name.toLowerCase() === 'workbook tiles') {
            activity.url = '//activity/workbook-tiles/';
          }

          return activity;
        });
        console.log(activities);
      });
    this.listener.getPlan().pipe(takeUntil(this.unsubscribe$)).subscribe((plan: string) => {
      this.selectedPlan = plan;
    });

    // Sets the curriculums
    combineLatest([
      this.store.select(reducers.isSetCourseLoaded),
      this.store.select(reducers.getAllNonAndSubscriptionCourses)
    ])
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(([isDefaultCourseLoaded, allCourses]) => {
        if (!isDefaultCourseLoaded && allCourses?.length) {
          // let course = allCourses[0];
          // if (this.utilityService.mobileCheck()) {
          let course = allCourses[0];

          // NOTE: This action should only happen if a user navigates to the curriculum page without a course selected
          // This may eventually be removed to allow for an "Upgrade" modal to be shown to users who are not on a plan
          if(this.selectedPlan === "Demo" || this.selectedPlan === "Demo v2" || 
            this.selectedPlan === "Hero" || this.selectedPlan === "Hero v2" ||
            this.selectedPlan === "Superhero" || this.selectedPlan === "Superhero v2"
          ) {
            let firstUnsubscriptionCourse = allCourses.find((course: any) => !course.subscriptionId);

            console.log(firstUnsubscriptionCourse);
            if(firstUnsubscriptionCourse){
              course = firstUnsubscriptionCourse;
            }
          }

          this.store.dispatch(new SetCourseActions.SetCurriculumCourse({
            course,
            redirect: false
          }));
        }
      });

    this.hideWorkbookTabsPredicate$.pipe(
      distinctUntilChanged(),
      takeUntil(this.unsubscribe$)
    ).subscribe(res => {
      const paramsType = this.route.snapshot.params?.type;
      if (!res || paramsType === 'lesson' || this.curriculumService.activeTab === 'lesson') {
        this.selectedTabIndex = 1;
      } else {
        this.selectedTabIndex = 0;
      }
      this.tabClick({ index: this.selectedTabIndex });
    });

    this.store.select(reducers.selectGetAllCourses)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((courses: any) => {
        this.courses = courses;
      });

    this.manageCourseForm = this.formBuilder.group({
      courseName: [this.currentCourse.courseName, [Validators.maxLength(40), Validators.required, (control: AbstractControl) => {
        const exist = !!this.courses?.find(({ _id, courseName }) => courseName === control.value && _id !== this.currentCourse._id);
        if (exist) {
          return { duplicate: true };
        }
        return false;
      }]],
      notes: [this.currentCourse.notes, [Validators.maxLength(160)]],
    });

    // Watch for changes in the form controls
    this.manageCourseForm.valueChanges
      .pipe(distinctUntilChanged(), takeUntil(this.unsubscribe$))
      .subscribe((changes) => {
        this.isChanged = true; // Flag that changes were made
      });
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  openTutorialDialog(): void {
    const dialogRef = this.dialog.open(TutorialDialogComponent, {
      panelClass: 'tutorial_dialog_modal',
      data: {
        title: 'Curriculum',
        link: 'https://videos.sproutvideo.com/embed/069cd9b41510efcf8f/9ab3e41114adb2f4?type=sd'
      },
    });
    dialogRef.afterClosed().subscribe(result => {
    });
  }

  openQuickWorkbookAddTutorialDialog(): void {
    const dialogRef = this.dialog.open(TutorialDialogComponent, {
      panelClass: 'tutorial_dialog_modal',
      data: {
        title: 'Workbook Search',
        link: 'https://videos.sproutvideo.com/embed/ac91d2b31a1be5c225/61a1914b7d2fe8e8?playerTheme=light&amp;playerColor=186d9e'
      },
    });
    dialogRef.afterClosed().subscribe(result => {
    });
  }

  openDialog(currentCourseWorkbook): void {
    localStorage.setItem('currentCourseWorkbook', JSON.stringify(currentCourseWorkbook));
    // TODO: to investigate

    if (!JSON.parse(localStorage.profile).email_verified) {
      this.utilityService.openVerificationEmailDialog();
      return;
    }

    this.currentWorkbook = currentCourseWorkbook;
    if (
      !this.utilityService.isCourseWorkbookActive(currentCourseWorkbook)
    ) {
      const dialogRef = this.dialog.open(MessageDialogComponent, {
        panelClass: 'custom-confirm-dialog',
        data: {
          title: 'Workbook Content Missing',
          content: "Please click on the three dots on this workbook and then select 'Edit' to add content to this workbook before beginning it."
        }
      });
      dialogRef.afterClosed().subscribe();
      return;
    }

    this.listener.setCurriculumTab('workbook');
    this.activityDialog = this.dialog.open(CourseWorkbookMenuComponent, {
      panelClass: 'activity-modalbox',
      data: {
        ...currentCourseWorkbook,
        courseWorkbookId: currentCourseWorkbook._id,
        workbookId: currentCourseWorkbook.workbookId,
        activities: this.activities,
        isHomePage: true
      },
      width: '350px'
    });

    this.activityDialog.componentInstance.functions = {
      closeDialog: (activity) => this.activityDialog.close()
    };
  }

  copyWorkbook(courseWorkbookId) {
    const courseWorkbooks: any = this.async.transform(this.courseWorkbooks$);
    const courseWorkbook = courseWorkbooks.find(item => item._id === courseWorkbookId);
    const workbook = this.workbooks.find(item => item._id = courseWorkbook.workbookId);
    this.store.dispatch(new ClearWorkbookActivity());
    this.store.dispatch(new ClearActivityInitData());
    this.store.dispatch(new CourseWorkbookActions.CopyCourseWorkbook({
      courseWorkbook,
      workbook
    }));
  }

  addWorkbook(workbook) {
    const { uid, name } = JSON.parse(localStorage.profile).user_metadata;
    delete workbook._id;
    workbook.owner = uid;
    workbook.ownerName = name;
    this.workbookService
      .create(workbook)
      .pipe(
        take(1),
        switchMap((newWorkbook: any) => {
          return this.addCourseWorkbook(newWorkbook);
        }),
        switchMap(() => {
          return combineLatest([
            this.workbookService.getAll(),
            this.courseWorkbookService.getAll(this.currentCourse._id)
          ]);
        })
      )
      .subscribe(([workbooks, courseWorkbooks]) => {
        // @ts-ignore
        this.workbooks = workbooks;
        // @ts-ignore
        this.courseWorkbooksSubject.next(courseWorkbooks);
      });
  }

  addCourseWorkbookClickEvent(workbook) {
    this.addCourseWorkbook(workbook)
      .pipe(
        take(1)
      )
      .subscribe(() => { 
        this.scrollToBottom();
      });
  }

  addWhizzimoLibraryWorkbookClickEvent(workbook) {
    this.addToCourseFromWhizzimoLibrary(workbook)
      .pipe(
        take(1)
      )
      .subscribe(() => { 
        this.scrollToBottom();
      });
}

  addWorkbookToCourse(data) {
    const type = data.type;
    const workbook = data.workbook;

    if (type === "MyLibrary") {
      this.addCourseWorkbookClickEvent(workbook);
    } else {
      this.addWhizzimoLibraryWorkbookClickEvent(workbook);
    }
  }

  addCourseWorkbook(workbook) {
    return new Observable(observer => {
      let workbookData = workbook;

      // Prepare the form data without including the workbookData
      let formData = {
        ownerKey: workbook.owner,
        workbookId: workbook._id,
        courseId: this.currentCourse._id,
        concept: 'new',
        order: this.workbooks.length
      };

      this.courseWorkbookService.create(formData)
        .pipe(
          take(1),
          switchMap((result: any) => {
            // Augment workbook data with result from course creation
            workbookData = {
              ...workbookData,
              ...result.data
            };

            // Dispatch success actions
            this.store.dispatch(new AddWorkbookToCourseSuccess(workbookData));

            // Fetch all course workbooks after creation
            return this.courseWorkbookService.getAll(this.currentCourse._id);
          })
        )
        .subscribe(
          (courseWorkbooks) => {
            // Update local state with the retrieved course workbooks
            this.courseWorkbooksSubject.next(courseWorkbooks);
            this.sortCourseWorkbooks();
            observer.next(courseWorkbooks);
          },
          (error) => {
            observer.error(error); // Handle errors
          }
        );
    });
  }

  addToCourseFromWhizzimoLibrary(workbook) {
    return new Observable(observer => {
      const { uid } = JSON.parse(localStorage.profile).user_metadata;
      let courseWorkbookData = null;

      this.workbookService.copy(workbook._id, uid, false).pipe(
        take(1),
        switchMap((newWorkbook: any) => {
          // Save initial new workbook data
          courseWorkbookData = newWorkbook;

          // Create a new course workbook form
          let courseWorkbookForm = {
            ownerKey: newWorkbook.owner,
            workbookId: newWorkbook._id,
            courseId: this.currentCourse._id,
            concept: 'new',
            order: this.workbooks.length + 1
          };

          // Create the course workbook
          return this.courseWorkbookService.create(courseWorkbookForm);
        }),
        switchMap((createdCourseWorkbook: any) => {
          // Update courseWorkbookData with new workbook data
          courseWorkbookData = {
            ...courseWorkbookData,
            ...createdCourseWorkbook.data
          };

          // Dispatch success actions
          this.store.dispatch(new AddWorkbookToCourseSuccess(courseWorkbookData));

          const workbookData = {
            ...courseWorkbookData,
            _id: createdCourseWorkbook.data.workbookId
          };

          this.store.dispatch(new StoreWorkbookItemSuccess(workbookData));

          // Retrieve all course workbooks
          return this.courseWorkbookService.getAll(this.currentCourse._id);
        })
      ).subscribe(
        (courseWorkbooks) => {
          // Update local state and notify observers
          this.courseWorkbooksSubject.next(courseWorkbooks);
          this.sortCourseWorkbooks();
          observer.next(courseWorkbooks);
        },
        (error) => {
          observer.error(error); // Handle errors
        }
      );
    });
  }

  editWorkbook(workbookId) {
    this.router.navigate([`workbook-builder/${workbookId}`]);
  }

  deleteCourseWorkbook(id: string) {
    this.lessonPlanStepService
      .getCountByCourseWorkbookId(id)
      .pipe(
        take(1),
        map((count: number) => {
          if (count === 0) {
            // return this.courseWorkbookService.delete(id);
            this.store.dispatch(new CourseWorkbookActions.RemoveWorkbookFromCourse(id));
            return;
          }
          const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
            // panelClass: 'small-dialog',
            data: {
              title: 'Delete Workbook',
              message: 'This course workbook is used in one or more lesson plans, are you sure you wish to delete it?',
              deletion: true,
              confirmLabel: 'Delete'
            },
          });

          dialogRef.afterClosed().subscribe((canDelete) => {
            if (canDelete) {
              // return this.courseWorkbookService.delete(id);
              this.store.dispatch(new CourseWorkbookActions.RemoveWorkbookFromCourse(id));
            }
            // else {
            //   return new Observable(observer => observer.next(null));
            // }
          });
        }),
        // switchMap(() => this.courseWorkbookService.getAll(this.currentCourse._id))
      )
      .subscribe((courseWorkbooks) => {
        // @ts-ignore
        // this.courseWorkbooks = courseWorkbooks;
      },
        error => {
        }
      );
  }

  deleteCourseWorkbookEvent(id: string) {
    return this.lessonPlanStepService
      .getCountByCourseWorkbookId(id)
      .pipe(
        take(1),
        map((count: number) => {
          if (count === 0) {
            // return this.courseWorkbookService.delete(id);
            this.store.dispatch(new CourseWorkbookActions.RemoveWorkbookFromCourse(id));
            return;
          }
          const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
            // panelClass: 'small-dialog',
            data: {
              title: 'Delete Workbook',
              message: 'This course workbook is used in one or more lesson plans, are you sure you wish to delete it?',
              deletion: true,
              confirmLabel: 'Delete'
            },
          });

          dialogRef.afterClosed().subscribe((canDelete) => {
            if (canDelete) {
              // return this.courseWorkbookService.delete(id);
              this.store.dispatch(new CourseWorkbookActions.RemoveWorkbookFromCourse(id));
            }
            // else {
            //   return new Observable(observer => observer.next(null));
            // }
          });
        }),
        // switchMap(() => this.courseWorkbookService.getAll(this.currentCourse._id))
      )
      .subscribe((courseWorkbooks) => {

        let newCourseWorkbooks: any = this.async.transform(this.courseWorkbooks$);
        this.workbookCardsElement.setWorkbooks = newCourseWorkbooks.filter(item => item._id !== id);

        if(this.workbookCardsElement) {
          this.workbookCardsElement.makeReorder();
        }
      },
        error => {
        }
      );
  }

  confirmDeleteWorkbook(workbookId: string) {
    combineLatest([this.lessonPlanStepService.getCountByWorkbookId(workbookId), this.courseWorkbookService.getCountByWorkbookId(workbookId)])
      .pipe(take(1))
      .subscribe(([lessonPlanStepCount, courseWorkbookCount]) => {
        if (lessonPlanStepCount === 0 && courseWorkbookCount === 0) {
          return this.workbookService.delete(workbookId);
        }

        let confirmDetails = '';
        // @ts-ignore
        if (lessonPlanStepCount > 0) {
          confirmDetails += 'lesson plan steps';
        }

        // @ts-ignore
        if (courseWorkbookCount > 0) {
          confirmDetails += (confirmDetails === '' ? '' : ' and ') + 'courses';
        }

        const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
          // panelClass: 'small-dialog',
          height: '210px',
          width: '350px',
          panelClass: 'custom-confirm-dialog',
          data: {
            title: 'Delete Workbook',
            message: `This workbook is used in one or more ${confirmDetails}. Are you sure you wish to delete it?`,
            deletion: true,
            confirmLabel: 'Delete'
          },
        });

        dialogRef.afterClosed().subscribe((canDelete) => {
          if (canDelete) {
            return this.deleteWorkbook(workbookId);
          }
        });
      });
  }

  deleteWorkbook(workbookId: string) {
    const courseWorkbooks: any = this.async.transform(this.courseWorkbooks$);
    const courseWorkbook = courseWorkbooks.find(item => {
      return workbookId === item.workbookId;
    });
    if (courseWorkbook) {
      this.store.dispatch(new DeleteWorkbook({
        courseWorkbookId: courseWorkbook._id,
        workbookId
      }));
    }

  }
  toggleCourseMenu(courseMenuOpen: boolean) {
    this.courseMenuOpen = courseMenuOpen;
  }
  setCourse(newCourse: string) {
    // return;
    // this.spinner.show();
    combineLatest([this.store.select(reducers.selectGetAllCourses), this.store.select(reducers.selectGetAllSubscription)])
      .pipe(
        take(1),
        switchMap((allCourses: any) => {
          this.courses = allCourses[0].concat(allCourses[1]);
          this.courses = this.courses;
          const currentCourseId = newCourse ? newCourse : this.courses[0]._id;
          this.store.dispatch(new CourseWorkbookActions.GetAllCourseWorkbooks(currentCourseId));
          this.store.dispatch(new CoursesActions.GetCourse(currentCourseId));
          // localStorage.setItem('currentCourseId', currentCourseId);

          this.store.dispatch(new CoursesActions.GetCourse(currentCourseId));
          // localStorage.setItem('currentCourseId', currentCourseId);
          return combineLatest([
            this.workbookService.getAll(),
            this.store.select(reducers.selectGetAllCourseWorkbook),
            this.store.select(reducers.selectGetCourse)
          ]);
        }),
        takeUntil(this.unsubscribe$)
      )
      .pipe(
        delay(50),
        takeUntil(this.unsubscribe$)
      )
      .subscribe(([workbooks, courseWorkbooks, course]) => {


        // @ts-ignore
        this.workbooks = workbooks;
        // @ts-ignore
        this.currentCourse = course;
        this.courseService.currentCourse = this.currentCourse._id;
        this.hideWorkbookTabsPredicate = !(this.currentCourse.teacherKey === this.bartonUser && this.currentCourse.teacherKey !== this.userId);
        this.hideWorkbookTabsPredicate$.next(this.hideWorkbookTabsPredicate);
        // this.setLessonPlans(this.currentCourse._id);

        // @ts-ignore
        this.courseWorkbooksSubject.next(courseWorkbooks);

        this.sortCourseWorkbooks();
        // this.listener.curriculumCallback().subscribe((tab: string) => {
        //   if (tab === 'workbook') {
        //     this.selectedTabIndex = 0;
        //   } else {
        //     this.selectedTabIndex = 1;
        //   }
        // });
        // this.spinner.hide();
        // this.isLoading = false;

      });
  }
  // setLessonPlans(courseId: string) {
  // return this.lessonPlansService.getAllWithSteps(courseId)
  // .subscribe((lessonPlans: any[]) => {
  //   this.lessonPlans = lessonPlans;
  //   this.sortLessonPlans();
  //   this.lessonPlanOpen = lessonPlans.map(() => ({open: false}));

  //   if (Number.isNaN(this.selectedTabIndex)) {
  //    this.selectedTabIndex = 0;
  //   }
  // });
  // }

  toggleOpen(index: any) {
    this.lessonPlanOpen[index].open = !this.lessonPlanOpen[index].open;
  }

  tabClick(tab) {
    let tabType: 'lesson' | 'workbook';
    const params = this.route.params['value']
    this.selectedTabIndex = tab.index;
    const urlArr = (this.activatedRoute.url as any).value[0];
    let url = urlArr.path;

    if (tab.index === 0) {
      tabType = 'workbook';
    } else if (tab.index === 1) {
      tabType = 'lesson';
    }
    urlArr.parameters.type = tabType
    Object.entries(urlArr.parameters).forEach(([key, value]) => {
      if (value) {
        url += `;${key}=${value}`
      }
    })
    this.curriculumService.activeTab = tabType
    this.location.go(url)
  }

  edit(lessonPlanId: string) {
    // $event.stopPropagation();
    let lessonPlans: any = this.async.transform(this.lessonPlans$);
    const order = lessonPlans.length === 0 ? 0 : this.sortBy('order')[lessonPlans.length - 1].order + 1;
    this.router.navigate([`edit-lesson-plan/${lessonPlanId}/${this.currentCourse._id}/${order}`]);
  }

  begin(lessonPlan: any) {    // $event.stopPropagation();
    if (lessonPlan.lessonPlanStepCount === 0 || !lessonPlan.lessonPlanSteps.length) {
      return;
    }

    if (!JSON.parse(localStorage.profile).email_verified) {
      this.utilityService.openVerificationEmailDialog();
      return;
    }

    this.lessonPlanStepService
      .getAll(lessonPlan._id)
      .pipe(take(1))
      .subscribe((lessonPlanSteps: any[]) => {
        // Find the first lesson step that a user can do based on the activityName and their plan

        let sortedLessonPlanSteps = lessonPlanSteps.sort((a, b) => a.order - b.order);
        let firstStep = sortedLessonPlanSteps.find(step => this.utilityService.isAllowedStep(step.activityName, this.selectedPlan));

        if(!firstStep) {
          // NOTE: This action should be blocked by the ActivityGuard and the will end up stuck on that page.
          // FIXME: The upgrade model should be shown to the user if they can not access the lesson.
          return this.router.navigate([`my-curriculum`, {  selectedTabIndex: '1'}]);
        }

        const url = this.utilityService.getLessonPlanStepUrl(
          firstStep.activityName,
          firstStep.courseWorkbookId,
          lessonPlan._id,
          firstStep._id
        );
        this.store.dispatch(new GetLessonStep(firstStep._id));
        this.router.navigate([url]);
        this.listener.setCurriculumTab('lesson');
      });

    // this.lessonPlansService
    //   .getFirstStep(lessonPlan._id)
    //   .pipe(take(1))
    //   .subscribe((firstStep: any) => {

    //     const url = this.utilityService.getLessonPlanStepUrl(
    //       firstStep.activityName,
    //       firstStep.courseWorkbookId,
    //       lessonPlan._id,
    //       firstStep._id
    //     );
    //     this.store.dispatch(new GetLessonStep(firstStep._id));
    //     this.router.navigate([url]);
    //     this.listener.setCurriculumTab('lesson');
    //   });
  }

  copy(lessonPlanId: string) {
    // $event.stopPropagation();
    // this.lessonPlansService
    //   .copy(lessonPlanId)
    //   .subscribe(
    //     (copiedLesson: any) => {
    //       copiedLesson.order = this.lessonPlans[this.lessonPlans.length - 1].order + 1;
    //       this.lessonPlans.push(copiedLesson);
    //       this.lessonPlanOpen.push({open: false});
    //     },
    //     error => {
    //     }
    //   );
    this.store.dispatch(new CopyLesson(lessonPlanId));
  }

  delete(lessonPlanId: string) {
    // $event.stopPropagation();
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      // panelClass: 'small-dialog',
      height: '180px',
      width: '360px',
      panelClass: 'custom-confirm-dialog',
      data: {
        title: 'Delete Lesson Plan',
        message: `Are you sure you want to delete this lesson?`,
        // hideCancel: true,
        deletion: true,
        confirmLabel: 'Delete'
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        // this.lessonPlansService
        // .delete(lessonPlanId)
        // .pipe(
        //   take(1)
        // )
        // .subscribe(
        //   () => {
        //     const matchedIndex = this.lessonPlans.findIndex(lessonPlan => lessonPlan._id === lessonPlanId);
        //     this.lessonPlans.splice(matchedIndex, 1);
        //     this.lessonPlanOpen.splice(matchedIndex, 1);
        //   },
        //   error => {
        //   }
        // );
        this.store.dispatch(new DeleteLesson(lessonPlanId));
      }
    });
  }

  deleteEvent(lessonPlanId: string) {
    // $event.stopPropagation();
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      // panelClass: 'small-dialog',
      height: '180px',
      width: '360px',
      panelClass: 'custom-confirm-dialog',
      data: {
        title: 'Delete Lesson Plan',
        message: `Are you sure you want to delete this lesson?`,
        // hideCancel: true,
        deletion: true,
        confirmLabel: 'Delete'
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        // this.lessonPlansService
        // .delete(lessonPlanId)
        // .pipe(
        //   take(1)
        // )
        // .subscribe(
        //   () => {
        //     const matchedIndex = this.lessonPlans.findIndex(lessonPlan => lessonPlan._id === lessonPlanId);
        //     this.lessonPlans.splice(matchedIndex, 1);
        //     this.lessonPlanOpen.splice(matchedIndex, 1);
        //   },
        //   error => {
        //   }
        // );
        this.store.dispatch(new DeleteLesson(lessonPlanId));

        let newLessonPlans: any = this.async.transform(this.lessonPlans$);
        this.lessonCardsElement.lessonPlans = newLessonPlans.filter(item => item._id !== lessonPlanId);
    
        if(this.lessonCardsElement) {
          this.lessonCardsElement.makeReorder();
        }
      }
    });
  }


  move($event: any, index: number, isUp: boolean) {
    let lessonPlans: any = this.async.transform(this.lessonPlans$);
    $event.stopPropagation();
    if ((isUp && index === 0) || (!isUp && index === lessonPlans.length - 1)) {
      return;
    }

    const nextIndex = isUp ? index - 1 : index + 1;
    const tempOrder = lessonPlans[index].order;
    lessonPlans[index].order = lessonPlans[nextIndex].order;
    lessonPlans[nextIndex].order = tempOrder;
    this.lessonPlansSubject.next(lessonPlans);
  }

  sortBy(prop: string): any[] {
    let lessonPlans: any = this.async.transform(this.lessonPlans$);

    return lessonPlans.sort((a, b) => {
      if (a[prop] < b[prop]) {
        return -1;
      }

      if (a[prop] > b[prop]) {
        return 1;
      }

      return 0;
    });

  }

  createNew() {
    if (!JSON.parse(localStorage.profile).email_verified) {
      this.utilityService.openVerificationEmailDialog();
      return;
    }

    let lessonPlans: any = this.async.transform(this.lessonPlans$);

    const order = lessonPlans.length === 0 ? 0 : this.sortBy('order')[lessonPlans.length - 1].order + 1;
    this.router.navigate([`edit-lesson-plan/new/${this.currentCourse._id}/${order}`]);
  }
  

  manageCourse() {
    if (this.isManaging) {
      // Done button showing
      if (this.manageCourseForm.invalid) {
        return;
      }
      this.isLoadingSubject.next(true);
      let reorderObservable = of(null); // Default observable if no reordering
  
      if (this.areCourseWorkbooksChanged || this.areLessonPlansChanged) {
        reorderObservable = this.reOrder(false); // Get reOrder observable
      }
  
      reorderObservable.pipe(
        switchMap(() => {
          if (this.isChanged) {
            return this.onSaveCourse(); // Save course after reordering
          }
          return of(null); // Do nothing if no changes
        }),
        finalize(() => {
          this.isManaging = false;
          this.canReOrder = false;
          if(!this.isChanged) {
            this.isLoadingSubject.next(false);
          }
        })
      ).subscribe();
    } else {
      // Manage Course button showing

      if(this.selectedPlan === 'Demo v2') {
        return;
      }

      this.isManaging = !this.isManaging;
      this.canReOrder = true;
    }
  }

  isFirstCourse() {
    const previousSelectedCourse = JSON.parse(localStorage.getItem('previousSelectedCourse'));
    if (this.courses.length > 0 && this.courses[0]._id === previousSelectedCourse?._id) {
      return true;
    }
    return false;
  }

  mustDelete = () => {
    const usersCourses = this.courses.filter((value) => {
      return !value.subscriptionId;
    });
    // if (usersCourses.length < 2 || isFirstCourse()) {
    //   return false;
    // } else {
    //   return true;
    // }

    console.log(usersCourses);
    if (usersCourses.length) {
      return true;
    }
  };

  onSaveCourse(redirect: boolean = true) {
    if (this.manageCourseForm.valid) {
      const course = {
        ...this.currentCourse,
        ...this.manageCourseForm.value
      };
      if(redirect) {
        this.store.dispatch(new CoursesActions.UpdateCourse(course));
      } else {
        this.store.dispatch(new CoursesActions.UpdateCourseWithoutRedirect(course));
      }
      this.store.dispatch(new CourseWorkbookActions.GetAllCourseWorkbooks(course._id));
      return of(null);
    }
    return of(null);
  }

  onCopyCourse() {
    if (!this.isFirstCourse() || (this.selectedPlan !== 'Superhero' && this.selectedPlan !== 'Hero' && this.selectedPlan !== 'Demo' && this.selectedPlan !== 'Demo v2')) {

      let redirect = (this.selectedPlan !== "Hero v2" && this.selectedPlan !== "Superhero v2") ? true : false;
      this.onSaveCourse(redirect);
      
      if(redirect) {
        this.store.dispatch(new CoursesActions.CopyCourse(this.currentCourse._id));
      } else {
        this.store.dispatch(new CoursesActions.CopyCourseWithoutRedirect(this.currentCourse._id));
      }
    }
  }

  onDeleteCourse() {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      // panelClass: 'small-dialog',
      height: '180px',
      width: '360px',
      panelClass: 'custom-confirm-dialog',
      data: {
        title: 'Delete Course',
        message: `Are you sure you want to delete this course?`,
        // hideCancel: true,
        deletion: true,
        confirmLabel: 'Delete'
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        console.log(result);
        const updatedCourses = this.courses.filter(course => course._id !== this.currentCourse._id)
        localStorage.setItem('currentCourseId', updatedCourses[0]._id);
        // this.router.navigateByUrl('my-curriculum');
        this.listener.callback(`course:${updatedCourses[0]._id}`);
        if (this.mustDelete()) {
          this.store.dispatch(new CoursesActions.DeleteCourse(this.currentCourse._id));
        }
      }
    });
  }

  openManageDialog() {
    this.store.select(reducers.selectGetAllCourses)
      .pipe(take(1))
      .subscribe((courses: any) => {
        const previousSelectedCourse = JSON.parse(localStorage.getItem('previousSelectedCourse'));
        const isFirstCourse = () => {
          if (courses.length > 0 && courses[0]._id === previousSelectedCourse?._id) {
            return true;
          }
          return false;
        };

        const mustDelete = () => {
          const usersCourses = courses.filter((value) => {
            return !value.subscriptionId;
          });
          // if (usersCourses.length < 2 || isFirstCourse()) {
          //   return false;
          // } else {
          //   return true;
          // }
          if (usersCourses.length) {
            return true;
          }
        };

        const dialogRef = this.dialog.open(ManageCourseDialogComponent, {
          width: '250px',
          panelClass: 'manage-course-modalbox',
          data: { currentCourse: { ...this.currentCourse }, canDelete: mustDelete(), isFirstCourse: isFirstCourse() }
        });
        dialogRef.afterClosed().subscribe(result => {
          if (result === 'deleted') {
            const updatedCourses = courses.filter(course => course._id !== this.currentCourse._id)
            localStorage.setItem('currentCourseId', updatedCourses[0]._id);
            // this.router.navigateByUrl('my-curriculum');
            this.listener.callback(`course:${updatedCourses[0]._id}`);
            if (mustDelete()) {
              this.store.dispatch(new CoursesActions.DeleteCourse(this.currentCourse._id));
            }
          }
        });

      });
  }

  quickStart() {
    // navigate to the first workbooks blackboard activity
    const courseWorkbooks: any = this.async.transform(this.courseWorkbooks$);
    const workbook = courseWorkbooks[0];
    this.router.navigate([`activity/blackboard/${workbook._id}`]);
  }

  // reOrder(reOrder: boolean) {
  //   if (!reOrder) {
  //     this.isLoadingSubject.next(true);
  //     if (this.selectedTabIndex === 0) {
  //       this.courseWorkbooks$.pipe(switchMap((courseWorkbooks: any[]) => {
  //         const requests = courseWorkbooks.map(cw => this.courseWorkbookService.update({ order: cw.order }, cw._id));
  //         return forkJoin(requests);
  //       }))
  //         .pipe(take(1))
  //         .subscribe(result => {
  //           this.isLoadingSubject.next(false);
  //         });
  //     }
  //     if (this.selectedTabIndex === 1) {
  //       this.lessonPlans$.pipe(switchMap((lessonPlans: any[]) => {
  //         const requests = lessonPlans.map(lp => this.lessonPlansService.update(lp._id, { order: lp.order }));
  //         return forkJoin(requests);
  //       }))
  //         .pipe(take(1))
  //         .subscribe(result => {
  //           this.isLoadingSubject.next(false);
  //         });
  //     }
  //   }
  //   if (!this.isChanged) {
  //     setTimeout(() => {
  //       this.isLoadingSubject.next(false);
  //     }, 100);

  //   }
  //   this.canReOrder = reOrder;
  // }

  reOrder(reOrder: boolean): Observable<any> {
    if (!reOrder) {
      const requests: Observable<any>[] = [];
  
      // Handle course workbooks reordering if changed
      if (this.areCourseWorkbooksChanged) {
        const courseWorkbooksUpdate$ = this.courseWorkbooks$.pipe(
          switchMap((courseWorkbooks: any[]) => {
            const updateRequests = courseWorkbooks.map(cw => this.courseWorkbookService.update({ order: cw.order }, cw._id));
            return forkJoin(updateRequests);
          }),
          take(1)
        );
        requests.push(courseWorkbooksUpdate$);
      }
  
      // Handle lesson plans reordering if changed
      if (this.areLessonPlansChanged) {
        const lessonPlansUpdate$ = this.lessonPlans$.pipe(
          switchMap((lessonPlans: any[]) => {
            const updateRequests = lessonPlans.map(lp => this.lessonPlansService.update(lp._id, { order: lp.order }));
            return forkJoin(updateRequests);
          }),
          take(1)
        );
        requests.push(lessonPlansUpdate$);
      }
  
      // If there are update requests, return forkJoin, otherwise return of(null)
      if (requests.length > 0) {
        return forkJoin(requests);  // This will wait for all requests to complete
      }
    }
  
    // Return an observable that completes immediately if no reordering or changes
    return of(null);
  }

  scrollToBottom() {
    // console.log("Should scroll.")

    let tabs = (this.tabGroup as any)._elementRef.nativeElement.querySelector('.mat-mdc-tab-body-wrapper');
    let wordsTab = tabs.firstChild;
    let scrollableView = wordsTab.querySelector('.mat-mdc-tab-body-content');

    scrollableView.scrollTo({ top: scrollableView.scrollHeight, behavior: 'smooth' });
  }

  unsubscribe(): void {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      height: '210px',
      width: '350px',
      panelClass: 'custom-confirm-dialog',
      data: {
        title: 'Unsubscribe',
        // message:  'Would you like to unsubscribe from this course?'
        message: 'Unsubscribing from a course is a permanent action. How would you like to proceed?',
        deletion: true,
        confirmLabel: 'Unsubscribe'
      },
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.store.dispatch(new SubscriptionsActions.DeleteSubscription(this.subscriptionId));
        window.location.reload();
      }
    });
  }

  changeCourseWorkbookOrder(courseWorkbooks: any) {
    this.courseWorkbooksSubject.next(courseWorkbooks);
    this.areCourseWorkbooksChanged = true;
    // this.isChanged = true;
    // courseWorkbooks = JSON.parse(JSON.stringify(courseWorkbooks));
    // const reorderedCourseWorkbooks = courseWorkbooks.map((cw, index) => {
    //   cw.order = index;
    //   return cw;
    // });

    // const requests = reorderedCourseWorkbooks.map(cw => this.courseWorkbookService.update({order: cw.order}, cw._id));

    // combineLatest(requests)
    //   .pipe(take(1))
    //   .subscribe((result: any[]) => {
    //     this.setCourse(this.currentCourse._id);
    //   });
  }

  changeLessonPlanOrder(lessonPlans: any) {
    this.lessonPlansSubject.next(lessonPlans);
    this.areLessonPlansChanged = true;
    // const reorderedLessonPlans = lessonPlans.map((lp, index) => {
    //   lp.order = index;
    //   return lp;
    // });

    // const requests = reorderedLessonPlans.map(lp => this.lessonPlansService.update(lp._id, {order: lp.order}));

    // combineLatest(requests)
    //   .pipe(take(1))
    //   .subscribe((result: any[]) => {
    //     this.setLessonPlans(this.courseService.currentCourse._id);
    //   });
  }

  private sortByOrder(a: any, b: any) {
    if (a.order < b.order) {
      return -1;
    }
    if (a.order > b.order) {
      return 1;
    }

    return 0;
  }

  private sortCourseWorkbooks() {
    let courseWorkbooks: any = this.async.transform(this.courseWorkbooks$);
    courseWorkbooks = [...courseWorkbooks].sort(this.sortByOrder);
    this.courseWorkbooksSubject.next(courseWorkbooks);
    if (this.isChanged) {
      setTimeout(() => {
        this.isLoadingSubject.next(false);
        this.isChanged = false;
      }, 100);
    }

  }

  private sortLessonPlans() {
    let lessonPlans: any = this.async.transform(this.lessonPlans$);
    lessonPlans = lessonPlans.sort(this.sortByOrder);
    this.lessonPlansSubject.next(lessonPlans);
  }

  toggleMouseEnter(b: boolean) {
    ActivityHeaderService.updateOverDeadzone(b);
  }
}
