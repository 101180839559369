import { NgModule } from '@angular/core';
import { CoreModule } from '../core/core.module';
import { SettingsRoutingModule } from './settings-routing.module';

import { SettingsService } from '../core/services/new-settings.service';

import { TileLayoutComponent } from './TileLayout/tileLayout.component';
import { LetterTilesComponent } from './Basic/basic.component';
import { AdvancedComponent } from './Advanced/advanced.component';
// import { AccountComponent } from './Account/account.component';
import { SettingsComponent } from './settings.component';

import {DndModule} from 'ng2-dnd';

import {TilesService} from '../core/services/word-filter-tiles.service';
import {FlexModule} from '@angular/flex-layout';
import {PairingsService} from '../core/services/pairings.service';
import {AccountService} from '../core/services/account.service';
import {StripeService} from '../core/services/stripe.service';
import {UtilityService} from '../core/services/utility.service';
import {PlanService} from '../core/services/plan.service';
import { DataService } from '../core/services/data.service';
import { NewAdvancedComponent } from './new-advanced/new-advanced.component';
// import {PlanDescriptionComponent} from './Account/plan-description.component';

@NgModule({
  declarations: [
    TileLayoutComponent,
    LetterTilesComponent,
    AdvancedComponent,
    // AccountComponent,
    SettingsComponent,
    NewAdvancedComponent,
    // PlanDescriptionComponent
  ],
  imports: [
    CoreModule,
    SettingsRoutingModule,
    // DndModule.forRoot(),
    FlexModule,
  ],
  exports: [
  ],
  providers: [SettingsService, TilesService, PairingsService, AccountService, UtilityService, PlanService, DataService ]
})
export class SettingsModule {}
