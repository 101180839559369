import { Injectable } from '@angular/core';
import {Observable} from 'rxjs';
import {API_DIR, PUBLISHABLE_KEY} from '../../environments/environment';
import {take} from 'rxjs/operators';
import {HttpClient} from '@angular/common/http';
declare const Stripe: any;
Stripe.setPublishableKey(PUBLISHABLE_KEY);

@Injectable()
export class StripeService {
  // public stripe: any;

  constructor(private http: HttpClient) {
    // this.stripe = Stripe(PUBLISHABLE_KEY);
  }

  validateCreditCard(cardInfo) {
    const errors: any = {};
    // Checking if the number is less than 19. It's 19 and not 16 because the spaces are included.
    // edit: american express cards would have 17, not 19
    if (!Stripe.card.validateCardNumber(cardInfo.number.replace(/\s+/g, ''))) {
      errors.number = true;
    }

    if (!Stripe.card.validateCVC(cardInfo.cvc)) {
      errors.cvc = true;
    }

    if (!Stripe.card.validateExpiry(cardInfo.exp_month, cardInfo.exp_year)) {
      errors.expiry = true;
    }
    const currentYear = new Date().getFullYear();
    if (cardInfo.exp_year - Number(currentYear) >= 10) {
        errors.expiry = true;
    }

    return errors;
  }
  validateCreditCardNoNumber(cardInfo) {
    const errors: any = {};

    if (!Stripe.card.validateCVC(cardInfo.cvc)) {
      errors.cvc = true;
    }

    if (!Stripe.card.validateExpiry(cardInfo.exp_month, cardInfo.exp_year)) {
      errors.expiry = true;
    }

    return errors;
  }

  createToken(cardInfo) {
    return new Observable(observer => {
      Stripe.card.createToken(cardInfo, (status, token) => {
        observer.next(token);
      });
    });
  }

  // confirmCardSetup(setupIntent, cardInfo) {
  //   return new Observable(observer => {
  //     // Confirm card setup
  //     this.stripe.confirmCardSetup(
  //       setupIntent,
  //       {
  //         payment_method: {
  //           card: cardInfo,
  //           // billing_details: {
  //           //   name: 'Jenny Rosen',
  //           // },
  //         },
  //       }
  //     ).then(function (result) {
  //       if (result.error) {
  //         // Display error.message in your UI.
  //       } else {
  //         // The setup has succeeded. Display a success message.
  //       }
  //     });
  //   });
  // }

  getAllPlans() {
    return new Observable(observer => {
      this.http
        .get(`${API_DIR}/account/products`)
        .pipe(
          take(1)
        )
        .subscribe(
          (plans: any) => observer.next(plans.data),
          (error: any) => {
            throw error;
          }
        );
    });
  }

  // createSetupIntent() {
  //   return new Observable(observer => {
  //     this.http
  //       .post(`${API_DIR}/payment/card-setup`, {})
  //       .pipe(
  //         take(1)
  //       )
  //       .subscribe(
  //         (intent: any) => observer.next(intent.data),
  //         (error: any) => {
  //           throw error;
  //         }
  //       );
  //   });
  // }
}
