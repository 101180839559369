import { SetCurrentSetting } from '../../store/settings/settings.actions';
import {
  Router, Event, NavigationStart,
  NavigationEnd, NavigationError, ActivatedRoute
} from '@angular/router';
import { filter } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { Component, OnInit, Input, Output, OnDestroy, ChangeDetectionStrategy } from '@angular/core';
import { AddSavedSettingsDialogComponent } from '../add-saved-settings-dialog/add-saved-settings-dialog.component';
import { SettingsService } from '../../services/new-settings.service';
import { MatDialog } from '@angular/material/dialog';
import { UserSettingsService } from '../../services/user-settings.service';
import { UserService } from '../../services/user.service';
import { ListenerService } from '../../services/listener.service';
import { TutorialDialogComponent } from '../support/support.component';
import { _variable_images } from '../../../environments/environment';
// import { settings } from 'cluster';
import { DataService } from '../../services/data.service';
import { BehaviorSubject, Observable, Subject, Subscription } from 'rxjs';
import { selectGetAllSettings, getCurrentSetting } from '../../store';
import * as SettingsActions from "../../store/settings/settings.actions";
import { takeUntil } from 'rxjs/operators';
import { AsyncPipe } from '@angular/common';
interface ISettingOptions {
  value: string;
  name: string;
  settingsId?: string;
}
@Component({
  selector: 'app-settings-header',
  templateUrl: './settings-header.component.html',
  styleUrls: ['./settings-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class SettingsHeaderComponent implements OnInit, OnDestroy {

  @Input() heading: string;
  @Input() videoLink: string;
  @Input() isAccount = false;

  heading_class: string;

  userSettings: any;
  public currentSettings: any;
  settingsOptions: ISettingOptions[];
  currentSettingName: string;
  activitySettings: any;
  previousUrl: string;

  previousUrlSubject: any = null;
  selectedPlan: string;

  previousUrl$: any;
  pencilImage: any;
  helpImage: any;
  subscriptions = new Subscription();
  private unsubscribe$: Subject<void> = new Subject();
  settingOptions$: Observable<any>;
  isActivity = false;
  constructor(
    private dialog: MatDialog,
    private settingsService: SettingsService,
    private userService: UserService,
    private listnerService: ListenerService,
    private dataService: DataService,
    private store: Store,
    private router: Router,
    private async: AsyncPipe
  ) {
    // Grabs the user's plan to disable certain settings depending on what plan they are on.
    this.listnerService.getPlan().pipe(takeUntil(this.unsubscribe$)).subscribe((res: any) => {
      this.selectedPlan = res;
    });
  }
  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  ngOnInit() {
    this.dataService._variable_images.pipe(takeUntil(this.unsubscribe$)).subscribe(path => {
      this.pencilImage = path + 'pencil.png';
      this.helpImage = path + 'help-header.png';
    });
    // this.router.events
    // .pipe(
    //   filter(event => event instanceof NavigationEnd))
    // .subscribe((event: NavigationEnd) => {
    //   this.previousUrl = event.url;
    //   if (this.previousUrlSubject == null) {
    //     this.previousUrlSubject = new BehaviorSubject<any>(this.previousUrl);
    //     this.previousUrl$ = this.previousUrlSubject.asObservable();
    //   }

    //   if (event.url === '/account') {
    //     this.previousUrlSubject.next('/my-curriculum');
    //   } else {
    //     this.previousUrlSubject.next(event.url);
    //   }

    // });
    this.previousUrl = localStorage.getItem('previousUrl');
    this.isActivity = this.previousUrl?.split('/').includes('activity') ? true : false;
    if (this.previousUrlSubject == null) {
      this.previousUrlSubject = new BehaviorSubject<any>(this.previousUrl);
      this.previousUrl$ = this.previousUrlSubject.asObservable();
    }

    if (this.router.url.split('/')[1] === '/account') {
      this.previousUrlSubject.next('/my-curriculum');
    } else {
      this.previousUrlSubject.next(localStorage.getItem('previousUrl'));
    }

    // this.subscriptions.add(
    //   this.settingsService.activitySettingsSubjet$.subscribe((settings: any) => {
    //     this.init();
    //   })
    // );
    // const interval = setInterval(() => {
    //   if (this.settingsService.activitySettings) {
    //     this.init();

    //     clearInterval(interval);
    //   }
    // }, 300);

    this.store.select(getCurrentSetting).pipe(takeUntil(this.unsubscribe$)).subscribe((setting: any) => {
      if (!setting) {
        this.activitySettings = null;
        this.currentSettingName = 'Unnamed';
        return;
      }
      this.activitySettings = setting;
      this.currentSettingName = setting.name;
    });

    this.settingOptions$ = this.store.select(selectGetAllSettings);

    // [class]="'settings-header intercom_' + this.heading.toLowerCase().replaceAll(' ', '_').replaceAll('&', 'and') + '_page'"

    this.heading_class = 'intercom_' + this.heading.toLowerCase().replace(/ /g, '_').replace(/&/g, 'and') + '_page';

    this.init();
  }

  init() {
    let previousUrl: any = this.async.transform(this.previousUrl$);
    // this.pencilImage = _variable_images + 'pencil.png';
    // this.helpImage = _variable_images + 'help-header.png';
    // if (this.settingsService.updateActivitySettings) {
    //   this.settingsService.updateActivitySettings
    //     .subscribe((settings: any) => {
    //       this.currentSettings = settings;
    //     });
    // }


    this.getSettingsOptions();
    // this.getSettings();
    this.listnerService.listenPreviousUrl().pipe(takeUntil(this.unsubscribe$)).subscribe((res: string) => {
      this.previousUrlSubject.next(res);
    }, err => {
    });

  }
  openDialog(): void {
    const dialogRef = this.dialog.open(TutorialDialogComponent, {
      panelClass: 'tutorial_dialog_modal',
      data: {
        title: this.heading,
        link: this.videoLink
      },
    });
    dialogRef.afterClosed().subscribe(result => {
    });
  }

  onSettingChange(event: any): void {
    const selectedValue = event.value;

    console.log(this.currentSettingName);

    // If "ADD A SAVED SETTING" is selected
    if (selectedValue === "st-add") {
      // Open the dialog, keeping the current setting value unchanged
      this.openAddSettings(this.currentSettingName);
    } else {
      // Update the current setting name as usual
      this.setCurrentSetting(this.settingsOptions.find((setting) => setting.name === selectedValue));
    }
  }

  openAddSettings(previousSettingName: string): void {
    console.log(previousSettingName);
    
    const settings = this.async.transform(this.settingOptions$);

    // We can prevent the user from adding more settings than their plan allows. Currently, we are just preventing this for demo users
    const planLimits = {
      "Demo v2": 0,
      // "Hero v2": 25,
      // "Superhero v2": 50,
      // "Superhero Plus v2": 50
    };

    if (planLimits[this.selectedPlan] !== undefined && settings.length >= planLimits[this.selectedPlan]) {
      return;
    }

    const dialogRef = this.dialog.open(AddSavedSettingsDialogComponent, {
      panelClass: 'add-save-setting-modalbox',
      // height: '350px',
      data: { action: 'add', settingsAll: this.settingsOptions }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        // this.settingsService.updateSettings(result);
      } else {
        // Reset to the previous setting if dialog was canceled
        this.currentSettingName = previousSettingName;
      }
      // if('cop')
      // this.getSettingsOptions();
    });
  }
  navigate() {
    let previousUrl: any = this.async.transform(this.previousUrl$);
    if (previousUrl.split('/').includes('activity')) {
      this.router.navigateByUrl(previousUrl);

    } else {
      this.router.navigateByUrl('settings' + previousUrl);
    }

  }
  editSettings(settings: any, ev): void {
    ev.stopPropagation();
    const dialogRef = this.dialog.open(AddSavedSettingsDialogComponent, {
      // height: '350px',

      panelClass: 'save-setting-modalbox',
      data: { action: 'edit', settings: settings, settingsAll: this.settingsOptions }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result?.copy) {
        // this.settingsService.updateSettings(result.data);
      }
      // else {
      // this.getSettingsOptions(result);

      // }

    });
  }

  getSettings(): void {
    // this.settingsService
    //   .get(JSON.parse(localStorage.profile).user_metadata.uid)
    //   .subscribe((userSettings: any) => {
    //     this.userSettings = userSettings;
    //     this.currentSettingName = userSettings.name || 'Unnamed';
    //     this.settingsService.activitySettings = userSettings;
    //   });
    this.store.select(getCurrentSetting).pipe(takeUntil(this.unsubscribe$)).subscribe(setting => {
      if (!setting) {
        this.userSettings = null;
        return;
      }
      this.userSettings = JSON.parse(JSON.stringify(setting));
      this.currentSettingName = this.userSettings.name || 'Unnamed';
    });
  }

  getSettingsOptions(edit = false, copy = false) {
    this.settingsOptions = [];
    const id = JSON.parse(localStorage.profile).user_metadata.uid;

    this.settingOptions$.pipe(takeUntil(this.unsubscribe$)).subscribe(
      (settings: any) => {
        // this.settingsService.getAll(id).subscribe((settings: any) => {

        // if (!this.settingsService.activitySettings || edit === true) {
        //   this.settingsService.activitySettings = settings.find((settings) => settings.isCurrent );
        // }
        // if (!this.settingsService.activitySettings || this.settingsService.activitySettings === 'undefined') {
        //   this.settingsService.activitySettings = settings[0];
        //   settings[0].isCurrent = true;
        // } else if (copy) {
        //   this.settingsService.activitySettings = settings[settings.length - 1];

        // }

        // this.activitySettings = settings;
        // this.currentSettingName = this.settingsService.activitySettings.name || 'Unnamed';
        this.settingsOptions = settings;
        // settings.map((value) => {
        //   this.settingsOptions.push({...value , ...{value: `st-${value.name}`, name: value.name, settingsId: value._id}});
        // });

      });


  }

  getSettingsById(setting) {
    // this.settingsService.get(Id).subscribe((settings: any) => {
    // }, err => {
    // })
    // this.activitySettings.forEach((setting, i, array) => {
    //   if (i !== index) {
    //     setting.isCurrent = false;
    //   }
    // });
    // this.settingsService.updateCurrent(this.activitySettings[index]._id, this.activitySettings[index].ownerKey);
    // this.settingsService.updateSettings(this.activitySettings[index]);
  }

  setCurrentSetting(setting) {
    // NOTE: Make sure that this does not break anything with Demo. They should not be able to change the settings
    if (this.selectedPlan === "Demo v2") {
      return;
    }
    this.currentSettingName = setting.name;
    this.store.dispatch(new SettingsActions.SetCurrentSetting({ setting, refresh: false }));
  }
}
